/**
 * mkg-sidebar
 * https://github.com/mkg0/mkg-sidebar
 */

(function () {
  var mSidebar = function (params) {
    var itemsData = []; //{text:string,link:string,title:string,follow:bool}
    var options = {
      baseURL: '',
      position: 'left', // left, top, bottom, right
      closeButton: true,
      closeOnBackgroundClick: true,
      animationType: 'slide',
      defaultTitle: '',
      defaultFollow: true,
      onOpen: null,
      onClose: null,
      autoCollapse: false // auto collapse on close
    };
    var target = undefined;

    var _create = function () {
      for (var i = 0; i < arguments.length; i++) {
        let argument = arguments[i];
        if (Object.prototype.toString.call(argument) == '[object Array]') {
          for (var i2 = 0; i2 < argument.length; i2++) {
            addItem(argument[i2], false);
          }
        } else if (typeof argument === 'object') {
          for (let variable in argument) {
            options[variable] = argument[variable];
          }
        } else if (typeof argument === 'function') {
          options.onOpen = argument;
        }
      }


      var newBar = document.createElement('aside');
      target = newBar;
      newBar.className += ` mSidebar mSidebar--${options.animationType} mSidebar--${options.position}`;
      newBar.innerHTML =
        `<div class="mSidebar-container">
          <header>
              ${options.closeButton ? '<div class="mSidebar-close"></div>' : ''}
              <div class="mSidebar-header"></div>
          </header>
          <div class="mSidebar-content"></div>
          <footer class="mSidebar-footer"></footer>
      </div>`;
      refreshItems();
      document.body.appendChild(newBar);
      if (options.closeButton) {
        newBar.querySelector(".mSidebar-close").addEventListener('click', close.bind(this));
      }
      newBar.addEventListener('click', _onClick.bind(this));
      return this;

    }
    var _itemToHTML = function ({
      title,
      text,
      link,
      callback = null,
      follow = true,
      items = []
    }, depth = 0) {
      if (link && link.search(/^(https?|ftp):/) !== 0)
        link = options.baseURL.replace(/\/$/, '') + '/' + link.replace(/^\//, '');
      var result;
      var mItem = document.createElement(link ? 'a' : 'div');
      mItem.textContent = text
      if (link) {
        mItem.setAttribute('href', link);
        mItem.setAttribute('title', title ? title : text);
        mItem.setAttribute('follow', follow ? 'follow' : 'nofollow');
      }
      if (callback) mItem.addEventListener('click', callback.bind({
        title,
        text,
        link,
        follow,
        depth
      }))

      if (items.length === 0) {
        mItem.setAttribute('class', `mSidebar-item mSidebar--d${depth}`);
        result = mItem;
      } else {
        if (link)
          mItem.setAttribute('class', `mSidebar-collapse-header mSidebar--d${depth}`);
        else
          mItem.setAttribute('class', `mSidebar-collapse-header mSidebar--d${depth} mSidebar-collapse--buttonrole`);
        var linkHTML = link ?
          `<a href="${link}" title="${title ? title : text}"${follow ? '' : ' rel="nofollow"'} class="mSidebar-collapse-header mSidebar--d${depth}">${text}</a>` :
          `<div class="mSidebar-collapse-header mSidebar--d${depth} mSidebar-collapse--buttonrole">${text}</div>`;
        result = document.createElement('div');
        result.setAttribute('class', `mSidebar-collapse mSidebar--d${depth}`)
        result.innerHTML = `
              <div class="mSidebar-collapse-button mSidebar--d${depth}"></div>
              <div class="mSidebar-collapse-items mSidebar--d${depth+1}">
              </div>`;
        result.insertBefore(mItem, result.querySelector('.mSidebar-collapse-items'))
        items.forEach(function (eachItem) {
          result.querySelector('.mSidebar-collapse-items').appendChild(_itemToHTML(eachItem, depth + 1))
        })
      }
      return result;
    }

    var refreshItems = function () {
      var targetElement = target.querySelector('.mSidebar-content');
      targetElement.innerHTML = ""
      for (let i in itemsData) {
        targetElement.appendChild(_itemToHTML(itemsData[i]));
      }
      return methods;
    }

    var setContent = function (context) {
      target.querySelector('.mSidebar-content').innerHTML = context;
      return methods;
    }

    var setHeader = function (context) {
      target.querySelector('.mSidebar-header').innerHTML = context;
      return methods;
    }

    var setFooter = function (context) {
      target.querySelector('.mSidebar-footer').innerHTML = context;
      return methods;
    }
    var setContentFrom = function (context) {
      target.querySelector('.mSidebar-content').innerHTML = document.querySelector(context).innerHTML;
      return methods;
    }

    var setHeaderFrom = function (context) {
      target.querySelector('.mSidebar-header').innerHTML = document.querySelector(context).innerHTML;
      return methods;
    }

    var setFooterFrom = function (context) {
      target.querySelector('.mSidebar-footer').innerHTML = document.querySelector(context).innerHTML;
      return methods;
    }

    var removeItems = function () {
      itemsData = [];
      refreshItems();
      return methods;
    }

    var addItemFrom = function (context) {
      var open = document.querySelectorAll(context);
      var close = document.querySelectorAll(context);
      var toggle = document.querySelectorAll(context);
      var foundItems = document.querySelectorAll(context);
      for (var i = 0; i < foundItems.length; i++) {
        addItem({
          title: foundItems[i].getAttribute('title') ? foundItems[i].getAttribute('title') : options.defaultTitle,
          text: foundItems[i].innerHTML.replace(/<[^>]+>/g, ''),
          link: foundItems[i].getAttribute('href') ? foundItems[i].getAttribute('href') : '/',
          callback: foundItems[i].getAttribute('onClick') ? function (data) {
            eval(data)
          }.bind(this, foundItems[i].getAttribute('onClick')) : null,
          follow: foundItems[i].getAttribute('rel') === 'nofollow' ? false : options.defaultFollow
        }, false)
      }
      refreshItems();
      return methods;
    }
    var addItem = function ({
      title,
      text,
      link,
      callback = null,
      follow = true,
      items = []
    }, refresh = true) {
      if (arguments.length === 0) return false;
      if (typeof arguments[0] === 'string') {
        title = arguments[0];
        text = arguments[0];
        link = arguments[0].toLowerCase().replace(/[^a-z0-9]/, '');
      } else if (Object.prototype.toString.call(arguments[0]) == '[object Array]') {
        var items = arguments[0];
        for (let i = 0; i < items.length; i++) {
          addItem(items[i], false);
        }
        if (refresh) refreshItems();
        return methods;
      }
      let newItem = {
        title: title,
        text: text,
        link: link,
        callback: callback,
        follow: follow,
        items: items
      }
      itemsData.push(newItem);
      if (refresh) refreshItems();
      return methods;
    }

    var _onClick = function (e) {
      if (e.target === target && options.closeOnBackgroundClick) {
        close.call(this);
      }
      var clsName = ' ' + e.target.className + ' ';
      if (clsName.indexOf("mSidebar-collapse-button") > -1 || clsName.indexOf("mSidebar-collapse--buttonrole") > -1) {
        var parent = e.target.parentNode;
        if ((' ' + parent.className + ' ').indexOf(' mSidebar-collapse--open ') > -1) {
          parent.className = parent.className.replace(/( |$)mSidebar-collapse--open/, '');
        } else {
          parent.className += ' mSidebar-collapse--open';
        }
      }
    }
    var open = function () {
      if ((' ' + target.className + ' ').indexOf(" mSidebar--close ") > -1)
        target.className = target.className.replace('mSidebar--close', 'mSidebar--open');
      else
        target.className += ' mSidebar--open';
      if (options.onOpen) {
        options.onOpen.call(this);
      }
      return methods;
    }
    var close = function () {
      target.className = target.className.replace('mSidebar--open', 'mSidebar--close');
      if (options.autoCollapse) collapse();
      if (options.onClose) options.onClose.call(this);
      return methods;
    }
    var toggle = function () {
      if ((' ' + target.className + ' ').indexOf(" mSidebar--open ") > -1)
        close();
      else
        open();
      return methods;
    }
    var collapse = function () {
      var foundItems = target.querySelectorAll('.mSidebar-collapse--open');
      for (var i = 0; i < foundItems.length; i++) {
        foundItems[i].className = foundItems[i].className.replace(/ ?mSidebar-collapse--open/, '');
      }
      return methods;
    }
    var uncollapse = function () {
      var foundItems = target.querySelectorAll('.mSidebar-collapse:not(.mSidebar-collapse--open)');
      for (var i = 0; i < foundItems.length; i++) {
        foundItems[i].className += ' mSidebar-collapse--open';
      }
      return methods;
    }

    // bootstrap library
    _create(params);


    var methods = {
      refreshItems: refreshItems,
      setContent: setContent,
      setHeader: setHeader,
      setFooter: setFooter,
      setContentFrom: setContent,
      setHeaderFrom: setHeader,
      setFooterFrom: setFooter,
      removeItems: removeItems,
      addItemFrom: addItemFrom,
      addItem: addItem,
      open: open,
      close: close,
      toggle: toggle,
      collapse: collapse,
      uncollapse: uncollapse,
    };

    return methods;
  }

  if (typeof module !== 'undefined' && typeof module.exports !== 'undefined') {
    module.exports = mSidebar;
  } else {
    window.mSidebar = mSidebar;
  }
}())